import React, { useEffect, useState } from "react";
import { Pie, Line, Bar } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

import "./App.css";

// Render chart
function renderChart(data, chartType) {
  return (
    <div className="reddit_widget__post">
      {chartType === "pie" && renderPieChart(data)}
      {chartType === "bar" && renderBarChart(data)}
      {chartType === "line" && renderLineChart(data)}
    </div>
  );
}

function renderPieChart(data) {
  return <Pie data={data} />;
}

function renderBarChart(data) {
  return <Bar data={data} />;
}

function renderLineChart(data) {
  return <Line data={data} />;
}

function getName(type) {
  switch (type) {
    case "openTimePerTaskChart":
      return "Time per task";
    case "openTimePerProjectChart":
      return "Time per project";
    default:
      return "Chart";
  }
}

function App({ domElement }) {
  const type = domElement.getAttribute("type");
  const projectId = domElement.getAttribute("projectid");
  const userId = domElement.getAttribute("userid");
  const chartType = domElement.getAttribute("charttype");

  const [loading, setLoading] = useState();
  const [error, setError] = useState("");
  const [data, setData] = useState({ labels: [], datasets: [] });

  ChartJS.register(ArcElement, Tooltip, Legend);

  useEffect(() => {
    // Fetch data from Metric Soup
    let url = projectId
      ? `https://metricsoup.herokuapp.com/api/${type}/?userId=${userId}&projectId=${projectId}`
      : `https://metricsoup.herokuapp.com/api/${type}/?userId=${userId}`;

    setLoading(true);
    fetch(url)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        setData(data);
        //console.log(data);
      })
      .catch((e) => {
        //console.log(e);
        setLoading(false);
        setError("error fetching from Metric Soup");
      });
  }, [type, projectId, userId]);

  //console.log(data);

  return (
    <div className="reddit_widget__app">
      <h1 className="reddit_widget__header">{getName(type)} </h1>
      <div className="reddit_widget__inner">
        {loading && "Loading..."}
        {error && error}
        {!!data.datasets && renderChart(data, chartType)}
      </div>
      <p className="reddit_widget__powered_by">
        This widget is powered by{" "}
        <a
          href="https://metricsoup.herokuapp.com"
          rel="noopener noreferrer"
          target="_blank"
        >
          Metric Soup
        </a>
      </p>
    </div>
  );
}

export default App;
